<script>
import { mapState } from 'vuex';
import Dropdown from './Dropdown.vue';

export default {
  data() {
    return {
      showCart: false,
    };
  },

  computed: {
    ...mapState('cart', ['items']),
  },

  methods: {
    open() {
      this.showCart = true;
    },

    close() {
      this.showCart = false;
    },

    toggle() {
      this.showCart = !this.showCart;
    },
  },

  components: { Dropdown },
};
</script>

<template>
  <div class="flex align-center mx-4">
    <div class="hidden sm:block text-blue-500" @click="toggle">
      <span v-if="items.length > 0">{{ items.length }}</span>
      <i class="fas fa-shopping-cart"></i>
    </div>

    <Dropdown v-if="showCart" @onClose="close" />
  </div>
</template>
